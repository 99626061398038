.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(180deg, #00ffcc, #00ffff); 
  box-shadow: 0 0 24px #00ffff;
  border: 2px solid #00ffcc;
  border-radius: 100px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    text-shadow 0.3s ease;
  padding: 10px 20px;
  color: #09090b;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Text drop shadow */
}

.button:hover {
  background-color: #00ffcc !important;
  box-shadow: 0 0 34px #00ffcc !important;
  text-shadow: 0 0 4px #00ffcc; /* Hover text shadow */
  border-color: #00ffcc !important;
}

.icon {
  margin-right: 5px;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3)); /* Icon drop shadow */
}