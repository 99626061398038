

img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
}

.container{
    width: 400px;
    color: #fff;
    padding: 30px 40px;
    border-radius: 15px;
    background: rgba(5, 5, 5, .7);
}

.input-box{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 30px 0;
}

.input-box input{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #fff;
    border: 2px solid #fff;
    font-size: 16px;
    padding: 10px;
}

.input-box i{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.remember{
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    margin: -10px 0 15px;
}

.remember a{
    border-bottom: 2px solid transparent;
    color: #fff;
}

.remember a:hover{
    color: #000dff;
}

.btn{
    width: 100%;
    height: 40px;
    background: #04fff0;
    color: #000;
    border: none;
    outline: none;
    border-radius: 20px;
    box-shadow: 0 0 20px #04fff0;
    font-size: 15px;
    font-weight: 600;
    transition: 1s;
    margin-top: 20px;
}

.btn:hover{
    background: transparent;
    border: 2px solid #04fff0;
    color: #fff;
    transform: scale(1.1);
    box-shadow: 0 0 20px #04fff0;
}

.register{
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
}
