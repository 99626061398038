.inputdiv {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid rgb(1, 235, 252);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 0px 0px 100px rgb(1, 235, 252), inset 0px 0px 10px rgb(1, 235, 252), 0px 0px 5px rgb(255, 255, 255);
    animation: flicker 2s linear infinite;
    margin-top: 120px;
}

.icon {
    color: rgb(1, 235, 252);
    font-size: 2rem;
    cursor: pointer;
    animation: iconflicker 2s linear infinite;
}

.input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer !important; 
    z-index: 1; 
}

@keyframes flicker {
    0%, 100% {
        box-shadow: 0px 0px 100px rgb(1, 235, 252), inset 0px 0px 10px rgb(1, 235, 252), 0px 0px 5px rgb(255, 255, 255);
    }
    50% {
        box-shadow: 0px 0px 50px rgb(1, 235, 252), inset 0px 0px 5px rgb(1, 235, 252), 0px 0px 2px rgb(255, 255, 255);
    }
}

@keyframes iconflicker {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}
