/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

* {
  font-family: "Press Start 2P", cursive;
}

.App {
  background-color: black;
  min-height: 100vh; /* Ensures the black background covers at least the full viewport height */
  margin: 0; /* Removes any default margin */
  padding: 0; /* Removes any default padding */
  color: white;
  display: flex;
  font-weight: 400;
  font-style: normal;
}

html {
  cursor: url("arrow.cur"), auto;
}