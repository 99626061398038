.grid {
  z-index: 10; 
  position: relative; 
}


::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #0e0e0e; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #00ffcc, #00ffff); 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #00ffff, #00ffcc); 
  }
  